import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { DateIntl } from "./StyledText"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import Labels from "./Labels"

const Card = ({
  title,
  date,
  text,
  img,
  slug,
  labels = [],
  minimized = false,
}) => {
  return (
    <Link to={slug} className="link">
      <div className="max-w-sm overflow-hidden bg-gray-100 animate-hover-up border hover:border-blue-400">
        <div className="p-3">
          <Img
            className="w-full"
            fluid={img}
            alt={title}
            style={{ height: 200 }}
          />
        </div>
        <div className={`px-6 py-4 ${minimized ? " h-40" : " h-64"}`}>
          <p className="text-gray-500 mb-3 text-sm">
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-1 p2" />
            <DateIntl date={date} />
          </p>
          <div className="font-bold xs:text-base md:text-xl mb-2">{title}</div>
          <p className="text-gray-700 text-tiny pt-3">{text}</p>
        </div>
        <div className="md:px-6 xs:px-2 py-4 bg-gray-300">
          <Labels list={labels} />
        </div>
      </div>
    </Link>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  text: PropTypes.string,
  img: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  labels: PropTypes.array,
}

export default Card

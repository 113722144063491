import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import ScatterCircle from "../assets/scatter-circle.svg"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { Container } from "../components/Container"

function Presentation() {
  return (
    <div className="xs:min-h-3/4 md:min-h-full xs:overflow-hidden md:overflow-auto">
      <Container>
        <section className="xs:block md:flex content-center pt-8">
          <div className="flex-1 w-full sm:w-4/6 md:w-4/6 lg:w-4/6">
            <section className="p-4 mx-auto">
              <h4 className="md:text-3xl font-semibold xs:text-lg">
                <FormattedMessage id="personal.salute" />
              </h4>
              <h1 className="md:text-6xl font-semibold xs:text-3xl">
                <FormattedMessage id="personal.fullname" />
              </h1>

              <p className="text-gray-700 my-2">
                <FormattedMessage id="personal.presentation" />
              </p>
              <p className="text-gray-700">
                <FormattedMessage id="personal.presentationContinue" />
              </p>

              <small
                className="text-sm xs:mt-4 xs:block"
                style={{ fontSize: "0.8em" }}
              >
                @ogastonc
                <FontAwesomeIcon
                  icon={faTwitter}
                  style={{
                    fontSize: "1em",
                    width: "1em",
                    display: "inline-block",
                  }}
                  className="ml-1 p1 text-blue-500 twitter"
                />
              </small>
              <section className="pt-6 my-5">
                <button
                  onClick={() => scrollTo("#knowme-section")}
                  className="btn btn-blue mr-3 xs:mb-3"
                >
                  <FormattedMessage id="frontpage.knowme" />
                </button>
                <Link to="/blog">
                  <button className="btn btn-blue-outline">
                    <FormattedMessage id="frontpage.readMyBlog" />
                  </button>
                </Link>
              </section>
            </section>
          </div>
          <div className="xs:pl-8 flex-1 xs:flex-none w-full xs:w-1/2 xs:text-right sm:w-2/6 md:w-2/6 lg:w-2/6">
            <ScatterCircle />
          </div>
        </section>
      </Container>
    </div>
  )
}

export default Presentation

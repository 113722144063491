import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage, useIntl, Link } from "gatsby-plugin-intl"
import React from "react"
import { Button } from "../components/Buttons"
import Card from "../components/Card"
import { Container } from "../components/Container"
import Circle from "../assets/circle.svg"

export default function LastPost() {
  const { allContentfulBlogPost } = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { order: DESC, fields: createdAt }) {
        edges {
          node {
            objectID: id
            title
            slug
            node_locale
            labels
            createdAt
            contentful_id
            description
            image {
              fluid(maxWidth: 400) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)
  const { locale } = useIntl()

  const filterEdges = filterByLang(allContentfulBlogPost, locale)

  return (
    <div className="xs:mb-0 md:mb-8 pt-4 px-8 overflow-hidden xs:h-auto md:h-screen">
      <Container>
        <div className="w-full text-center ">
          <h2 className="text-3xl font-semibold">
            <FormattedMessage id="lastPost.title" />
          </h2>
        </div>

        <section className="my-12 xs:block md:flex flex-wrap pt-2">
          {filterEdges.map(({ node }, i) => (
            <div className={"flex-1 md:my-auto xs:my-6"} key={i}>
              <Card
                key={i}
                img={node.image && node.image.fluid}
                title={node.title || node.name}
                date={node.createdAt}
                slug={"/" + node.slug}
                text={true ? undefined : node.description}
                labels={node.labels.split(",")}
                minimized={true}
                online={node.online}
                github={node.github}
              />
            </div>
          ))}
        </section>
        <section className="xs:mb-8 xs:text-center text-right">
          <Link to="/blog">
            <Button primary outline>
              <FormattedMessage id="viewMore" />
            </Button>
          </Link>
        </section>

        <span className="md:inline xs:hidden">
          <Circle
            style={{
              position: "relative",
              zIndex: -10,
              bottom: "400px",
              right: "50%",
              opacity: "0.6",
            }}
          />
        </span>
      </Container>
    </div>
  )
}

function filterByLang(allContentfulBlogPost, lang) {
  return allContentfulBlogPost.edges
    .filter(o => {
      return o.node.node_locale.split("-")[0] === lang
    })
    .splice(0, 3)
}

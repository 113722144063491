import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import Points from "../assets/points.svg"
import Triangle from "../assets/triangle.svg"
import { Container } from "../components/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faReact, faNode, faJsSquare } from "@fortawesome/free-brands-svg-icons"
import Gatsby from "../assets/gatsby.svg"
import NestJs from "../assets/nestjs.svg"
import Pg from "../assets/postgresql-icon.svg"
import Firebase from "../assets/firebase-1.svg"
import Heroku from "../assets/heroku.svg"
import NextJS from "../assets/nextjs-3.svg"

export default function AboutMe() {
  const {
    allImageSharp: { nodes },
  } = useStaticQuery(
    graphql`
      query {
        allImageSharp(
          filter: { resolutions: { originalName: { regex: "/myself/" } } }
        ) {
          nodes {
            fluid {
              base64
              tracedSVG
              srcWebp
              srcSetWebp
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    `
  )

  return (
    <div
      id="knowme-section"
      className="overflow-hidden md:min-h-1/2 bg-gray-100 "
    >
      <Container>
        <div className="xs:mt-8 mt-20 mb-8 w-full text-center">
          <h2 className="text-3xl font-semibold">
            <FormattedMessage id="personal.title" />
          </h2>
        </div>
        <section className="content-center flex-wrap pt-8">
          <div className="xs:block md:flex">
            <div className="xs:w-full sm:w-2/6 md:w-2/6 lg:w-2/6">
              <img
                className="absolute xs:w-3/4 md:left-0 md:ml-20 md:w-1/4 z-10"
                src={nodes[0].fluid.originalImg}
                alt="Omar Gaston"
              />
              <small
                style={{
                  width: 200,
                  position: "relative",
                  left: "300%",
                  top: "-260px",
                }}
              >
                <Triangle />
              </small>
              <small
                style={{
                  width: "80%",
                  display: "block",
                  opacity: 0.8,
                  position: "relative",
                  left: "-35%",
                  top: "-200px",
                }}
              >
                <Points />
              </small>
            </div>
            <div className="xs:w-full xs:mb-8 md:mb-0 w-1/2 sm:w-4/6 md:w-4/6 lg:w-4/6">
              <p className="text-gray-700 mb-4">
                <FormattedMessage id="personal.coverletter" />
              </p>
              <p className="text-gray-700 mb-6">
                <FormattedMessage id="personal.coverletterContinue" />
              </p>
              <q className="text-2xl italic mt-4 text-gray-800">
                <FormattedMessage id="personal.quote" />
              </q>
              <p className="text-gray-700 my-6">
                <FormattedMessage id="personal.coverletterContinue2" />
              </p>
              <br />
              <p className="text-gray-700 mb-6">
                <FormattedMessage id="personal.coverletterFooter" />
              </p>
              <br />

              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                <div className="block w-full my-4 text-5xl text-blue-600">
                  <span className="text-sm block text-gray-700">React.js</span>

                  <FontAwesomeIcon icon={faReact} />
                </div>
                <div className="block w-full my-4 text-5xl text-green-600">
                  <span className="text-sm block text-gray-700">Node.js</span>

                  <FontAwesomeIcon icon={faNode} />
                </div>
                <div className="block w-full my-4 text-5xl text-yellow-400">
                  <span className="text-sm block text-gray-700">
                    Javascript
                  </span>

                  <FontAwesomeIcon icon={faJsSquare} />
                </div>
                <div className="block w-full my-4 text-5xl">
                  <span className="text-sm block text-gray-700">Gatsby</span>
                  <Gatsby
                    aria-hidden="true"
                    width="50"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="js-square"
                    className="svg-inline--fa "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                  />
                </div>
                <div className="block w-full my-4 text-5xl">
                  <span className="text-sm block text-gray-700">NestJs</span>
                  <NestJs
                    aria-hidden="true"
                    width="50"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="js-square"
                    className="svg-inline--fa "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                  />
                </div>

                <div className="block w-full my-4 text-5xl">
                  <span className="text-sm block text-gray-700">
                    Postgresql
                  </span>
                  <Pg
                    aria-hidden="true"
                    width="50"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="js-square"
                    className="svg-inline--fa "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                  />
                </div>
                <div className="block w-full my-4 text-5xl">
                  <span className="text-sm block text-gray-700">Firebase</span>
                  <Firebase
                    aria-hidden="true"
                    width="50"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="js-square"
                    className="svg-inline--fa "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                  />
                </div>
                <div className="block w-full my-4 text-5xl">
                  <span className="text-sm block text-gray-700">Heroku</span>
                  <Heroku
                    aria-hidden="true"
                    width="50"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="js-square"
                    className="svg-inline--fa "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                  />
                </div>
                <div className="block w-full my-4 text-5xl">
                  <span className="text-sm block text-gray-700">NextJS</span>
                  <NextJS
                    aria-hidden="true"
                    width="50"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="js-square"
                    className="svg-inline--fa "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  )
}

import React from "react"
import Layout from "../containers/Layout"
import SEO from "../components/seo"
import AboutMe from "../sections/AboutMe"
import LastPost from "../sections/LastPost"
import Presentation from "../sections/Presentation"
import Points from "../assets/points.svg"

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Design />
      <Presentation />
      <AboutMe />
      <LastPost />
    </Layout>
  )
}

function Design() {
  return (
    <>
      <small
        style={{
          width: "10%",
          display: "block",
          right: 0,
          bottom: "10%",
          position: "absolute",
          opacity: "0.8",
        }}
      >
        <Points />
      </small>

      <small
        style={{
          width: "10%",
          display: "block",
          left: -90,
          top: "15%",
          position: "absolute",
          opacity: "0.8",
        }}
      >
        <Points />
      </small>
      <small></small>
    </>
  )
}

export default IndexPage
